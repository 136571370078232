.Select {
  position: relative;

  .reset {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: rgb(61, 61, 61);
  }

  &:focus-within {
    .dropdown {
      top: 110%;
      opacity: 1;
      pointer-events: all;
    }
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: white;
    border: 2px solid rgb(0, 0, 0);
    border-radius: var(--input-radius);
    padding: 5px;
    font-size: 0.9rem;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: top 0.25s ease, opacity 0.25s ease;

    .item {
      padding: 4px 10px;
      border-radius: 5px;

      &:hover,
      &.selected {
        background: rgb(237, 237, 237);
      }

      &.disabled {
        color: rgb(135, 135, 135);
        &:hover {
          background: none;
        }
      }
    }
  }
}
