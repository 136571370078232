.Attend-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Attend {
  font-size: 1.5rem;
  height: 100svh;
  display: grid;
  place-items: center;
  padding: 20px;

  &.error {
    background: var(--error-mute);
    color: var(--error);
  }

  &.success {
    background: var(--success-mute);
    color: var(--success);
  }

  h2,
  p {
    text-align: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    input,
    button,
    select {
      font-size: 1.2rem;
      width: 100%;
    }

    .checkbox {
      display: flex;
      align-items: center;
      max-width: 20rem;
      gap: 10px;

      .checkbox-input {
        display: flex;
      }

      p {
        font-size: 0.9rem;
        text-align: left;
        color: rgb(57, 57, 57);
      }
    }
  }
}
