.Dashboard {
  width: 70rem;
  margin: auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .col {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
