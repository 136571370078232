.CreateRoom {
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .headline {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      background: none;
      border: none;
      color: black;
      padding: 0;
      padding: 5px;

      &:hover {
        box-shadow: none;
        transform: none;
        background: rgb(221, 221, 221);
        border-radius: 5px;
      }

      &:active {
        transform: none;
      }
    }
  }

  .filePicker {
    width: 25rem;
    height: 10rem;
    background: rgb(242, 242, 242);
    border: 2px dashed rgb(215, 215, 215);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    cursor: pointer;
    user-select: none;
    position: relative;
    isolation: isolate;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(230, 230, 230, 0.498);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .icon,
    .loader-spinner {
      position: absolute;
      z-index: 99;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);
    }
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
