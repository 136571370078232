@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --input-bg: #fbfbfb;
  --input-border: #dddddd;
  --input-radius: 9px;
  --input-padding: 10px 20px;

  --fg: black;
  --bg: white;

  --error: #e15e5e;
  --error-mute: #ffdddd;

  --success: #6aaf75;
  --success-mute: #efffdb;

  --danger: rgb(255, 67, 67);

  --text-gray: #9d9d9d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

input,
select {
  border: 1px solid var(--input-border);
  border-radius: var(--input-radius);
  background: var(--input-bg);
  padding: var(--input-padding);
}

button {
  border: 1px solid var(--fg);
  background: var(--fg);
  color: var(--bg);
  padding: var(--input-padding);
  border-radius: var(--input-radius);
  cursor: pointer;
  transition: transform 0.25s ease, box-shadow 0.25s ease, background 0.25s ease;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.2);
    background: rgb(10, 10, 10);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0);
  }

  &.danger {
    background: rgb(255, 67, 67);
    border: none;
  }

  &.success {
    background: var(--success);
    border: none;
  }

  &.action {
    background: none;
    padding: 0;
    color: black;
    aspect-ratio: 1 / 1;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.25s ease, background 0.25s ease, transform 0.25s ease,
      box-shadow 0.25s ease;

    &:hover {
      background: black;
      color: white;
    }

    &.danger {
      border: 1px solid var(--danger);
      color: var(--danger);

      &:hover {
        background: var(--danger);
        color: white;
      }
    }
  }

  &:disabled {
    background: rgb(42, 42, 42);
    border-color: rgb(42, 42, 42);
    pointer-events: none;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  th {
    text-align: left;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;

    &.actions {
      display: flex;
      gap: 5px;
    }
  }

  tr {
    padding-left: 20px;
  }

  tr:nth-child(even) {
  }

  tr:hover:not(:nth-child(1)) {
    background-color: #f9f9f9;
  }
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: var(--fg) !important;
}

.App {
  height: 100svh;
}

.ModalManager {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  isolation: isolate;
  pointer-events: none;

  .modal {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bg {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.119);
      backdrop-filter: blur(5px);
      pointer-events: all;
    }

    .modalWrapper {
      background: white;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.087);
      width: max-content;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }
}

.loader-spinner {
  animation: loader-spinner infinite 2s linear;
}

@keyframes loader-spinner {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
