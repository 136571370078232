.Account {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  user-select: none;
  position: relative;

  .dropdown {
    position: absolute;
    left: 0;
    top: 110%;
    width: 100%;
    background: rgb(252, 252, 252);
    border-radius: 10px;
    padding: 5px;
    display: none;
    border: 1px solid rgb(230, 230, 230);

    .item {
      display: flex;
      padding: 5px;
      gap: 5px;
      border-radius: 7px;

      &:hover {
        background: rgb(239, 239, 239);
      }

      &.logout {
        .icon,
        p {
          color: var(--danger);
        }
      }
    }
  }

  img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
    border-radius: 6px;
  }

  .text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 1.2rem;
      line-height: 70%;
    }

    p {
      font-size: 0.8rem;
      color: gray;
    }
  }

  &:hover {
    background: rgb(252, 252, 252);
    border-color: rgb(230, 230, 230);
  }

  &:focus-within {
    background: rgb(252, 252, 252);
    border-color: rgb(230, 230, 230);

    .dropdown {
      display: flex;
      flex-direction: column;
    }
  }
}
