.Room {
  .qr {
    border-radius: 12px;
  }

  .closed {
    text-align: center;
  }

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-size: cover !important;
    background-position: center !important;

    .coverSource {
      position: absolute;
      bottom: 0;
      right: 0;
      background: white;
      padding: 10px;
      border-top-left-radius: 10px;
    }

    .wrapper {
      display: flex;
      gap: 20px;

      .qr {
        border-radius: 10px;
        padding: 10px;
        background: white;
      }

      .text {
        max-width: 30rem;

        h1 {
          font-size: 4rem;
          background: white;
          width: min-content;
          margin-bottom: 10px;
          border-radius: 10px;
          padding: 0 10px;
        }

        h2 {
          background: white;
          border-radius: 10px;
          padding: 5px 10px;
        }
      }
    }
  }

  padding: 2rem 0;
  width: 70rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .headline {
    display: flex;
    justify-content: space-between;

    .name {
      display: flex;
      gap: 10px;

      .back {
        border: none;
        background: none;
        padding: 0;
        width: 3rem;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border: 1px solid rgb(173, 173, 173);
        }
      }
    }

    .actions {
      display: flex;
      gap: 10px;

      button {
      }
    }
  }

  .details {
    display: flex;
    gap: 2rem;

    .qr-container {
      .addCourse {
        margin-top: 20px;
        display: flex;
        gap: 10px;

        input {
          width: 100%;
        }
      }
    }

    .attendees {
      width: 100%;
    }
  }

  .attendees {
    .headline {
      align-items: center;
    }
  }
}
